<template>
  <div id="app">
<div id="login">
<!--
  
-->
    <div v-if="showModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
      <div class="relative w-auto my-6 mx-auto max-w-sm">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-3xl font-semibold">
              TE QUIERO
            </h3>
            <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" v-on:click="toggleModal()">
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <p class="my-4 text-blueGray-500 text-lg leading-relaxed">
              Por favoooo AfterSun. 
              <img src="https://www.prieto.es/images/product/1/large/pl_1_1_14467.jpg"  style="width: 60%;">
            <br>Cuida ese cuerpito sexy xfa🤭❤️
            </p>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
            <button class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" v-on:click="toggleModal()">
              CERRAR Y ENTRAR A LA APP
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>

    <div class="w-9/12 shadow-md rounded px-8 pt-6 pb-8 mb-4 mx-auto">
      <form v-if="!showContent" @submit.prevent="checkNumber" class="mb-4" style="background-color: rgb(227, 241, 245); padding: 10px; margin: 50px;">
        <label for="number" class="block text-gray-700 text-sm font-bold mb-2">Nuestro numero:</label>
        <input type="number" id="number" v-model="number" required class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
        <button style="background-color: black" type="submit" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline block mx-auto">ENVIAR</button>
      </form>

      <form v-if="showContent && !showSecondQuestion" @submit.prevent="checkRestaurant" class="mb-4" style="background-color: rgb(227, 241, 245); padding: 10px; margin: 50px;">
        <label for="restaurant" class="block text-gray-700 text-sm font-bold mb-2">¿Primer restaurante?</label>
        <input type="text" id="restaurant" v-model="restaurant" required class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
        <button style="background-color: black" type="submit" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline block mx-auto">ENVIAR</button>
      </form>

      <p v-if="errorMessage" class="text-red-500 text-xs italic">{{ errorMessage }}</p>

      <div v-if="showSecondQuestion" class="mb-4">
        <h2 class="font-bold text-xl mb-2">Rest of the Page</h2>
        <!-- Add the rest of your page content here -->
        <router-view />
        <p class="mt-4">Time remaining: {{ timeRemaining }} seconds</p>
      </div>
    </div>
  </div>
  </div>

<!--Modal-->


 

</template>

<style>
#login {
  font-family: Titillium Web, sans-serif;
  background-image: url("https://bali.minerolabs.com/plane.gif");
  background-repeat: repeat; /* Repite la imagen solo verticalmente */
  padding-bottom: 100%;
}
</style>

<script>
export default {
  name: "small-modal",
  data() {
    return {
      number: null,
      restaurant: '',
      showContent: false,
      showSecondQuestion: false,
      errorMessage: '',
      timeRemaining: 1000,
      showModal: false
    };
  },
  methods: {
    checkNumber() {
      this.errorMessage = '';
      if (this.number == 8) {
        this.showContent = true;
        this.startTimer();
      } else {
        this.errorMessage = 'ERROR.';
      }
    },
    checkRestaurant() {
      this.errorMessage = '';
      if (this.restaurant.toLowerCase() == 'vips') {
        this.showSecondQuestion = true;
      } else {
        this.errorMessage = 'Incorrect answer. Please try again.';
        this.restaurant = '';
      }
    },
    startTimer() {
      const intervalId = setInterval(() => {
        if (this.timeRemaining > 0) {
          this.timeRemaining--;
        } else {
          clearInterval(intervalId);
          this.resetForm();
        }
      }, 1000);
    },
    resetForm() {
      this.number = null;
      this.restaurant = '';
      this.showContent = false;
      this.showSecondQuestion = false;
      this.errorMessage = '';
      this.timeRemaining = 1000;
    },
    toggleModal: function(){
      this.showModal = !this.showModal;
    }
  }
};
</script>