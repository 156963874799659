<template>
  <!-- Header -->
  <div class="relative bg-emerald-600 md:pt-32 pb-32 pt-12" style="padding-top: 50px;">
    <h3 class="text-4xl font-normal leading-normal mt-0 mb-2 text-white text-center" style="padding-bottom: 30px;">
      ¿Que hora es?
    </h3>
    <div class="px-4 md:px-10 mx-auto w-full">
      <div>

        <!-- Card stats -->
        <div class="flex flex-wrap">
          
          <div class="w-full md:w-6/12 xl:w-4/12 px-4">
            <card-stats
              statSubtitle="HORA EN ESPAÑA"
              :statTitle="timeMadridFormatted"
              statPercentColor="text-emerald-500"
              :statDescripiron="dateMadridFormatted"
              statIconName="fas fa-city"
              statIconColor="bg-orange-200"
            />
          </div>
          <div class="w-full md:w-6/12 xl:w-4/12 px-4">
            <card-stats
              statSubtitle="HORA EN BALI"
              :statTitle="timeBaliFormatted"
              statPercentColor="text-red-500"
              :statDescripiron="dateBaliFormatted"
              statIconName="fas fa-vihara"
              statIconColor="bg-lightBlue-200"
            />
          </div>
          <div class="w-full md:w-6/12 lg:w-4/12 px-4">
            <card-stats
              statSubtitle="HORA LOCAL"
              :statTitle="timeLocalFormatted"
              statPercentColor="text-withe-500"
              :statDescripiron="dateLocalFormatted + ', Estas en: ' + timezoneLocal"
              statIconName="fas fa-train"
              statIconColor="bg-blueGray-200"
            />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardStats from "@/components/Cards/CardStats.vue";
import axios from 'axios';
import moment from 'moment';

export default {
  components: {
    CardStats,
  },
  data() {
    return {
      timeMadrid: '',
      timeMadridFormatted: '',
      dateMadridFormatted: '',

      timeBali: '',
      timeBaliFormatted: '',
      dateBaliFormatted: '',

      timeLocal: '',
      timeLocalFormatted: '',
      dateLocalFormatted: '',
      timezoneLocal: '',

    };
  },
  async created() {
    const responseMadrid = await axios.get('https://worldtimeapi.org/api/timezone/Europe/Madrid');
    this.timeMadrid = responseMadrid.data.datetime;
    this.timeMadridFormatted = moment.parseZone(this.timeMadrid).format('HH:mm');
    this.dateMadridFormatted = moment.parseZone(this.timeMadrid).format('DD/MM/YY');

    const responseBali = await axios.get('https://worldtimeapi.org/api/timezone/Asia/Makassar');
    this.timeBali = responseBali.data.datetime;
    this.timeBaliFormatted = moment.parseZone(this.timeBali).format('HH:mm');
    this.dateBaliFormatted = moment.parseZone(this.timeBali).format('DD/MM/YY');
    
    const responseLocal = await axios.get('https://worldtimeapi.org/api/ip');
    this.timeLocal = responseLocal.data.datetime;
    this.timeLocalFormatted = moment.parseZone(this.timeLocal).format('HH:mm');
    this.dateLocalFormatted = moment.parseZone(this.timeLocal).format('DD/MM/YY');
    this.timezoneLocal = responseLocal.data.timezone;
  },
};
</script>
