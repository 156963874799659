<template>
  <div>
    <index-navbar />
    
    <div class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500">
      <span class="text-xl inline-block mr-5 align-middle">
        <i class="fas fa-bell"></i>
      </span>
      <span class="inline-block align-middle mr-8">
        <b class="capitalize"> INFO!</b> Proximo y ultimo vuelo CX0315!
      </span>
      <button
        class="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none">
        <span>×</span>
      </button>
    </div>



    <section class="header relative pt-16 items-center flex h-screen max-h-860-px" style="background-color: white;">
      <div class="container mx-auto items-center flex flex-wrap">
        <div class="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
          <div class="pt-32 sm:pt-0">
            <div class="relative pt-1">
            </div>
            <h1 class="text-5xl font-normal leading-normal mt-0 mb-2 text-teal-800">
              {{ days }} días
            </h1>
            <h2 class="text-5xl font-normal leading-normal mt-0 mb-2 text-emerald-800">
              {{ hours }} h,
              {{ minutes }} min,
              {{ seconds }} seg
            </h2>
            <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
              Vete preparando, esta es la cuenta atras para que dos Koalas se abracen.
            </p>
            <p style="margin-top: 15px;"></p>
            <!--Componente barra-->
            <div class="relative pt-1">
              <div class="flex mb-2 items-center justify-between">
                <div>
                  <span
                    class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-emerald-600 bg-emerald-200">
                    {{ progressText }}
                  </span>
                </div>
                <div class="text-right">
                  <span class="text-xs font-semibold inline-block text-emerald-600">
                    {{ progress.toFixed(2) }}%
                  </span>
                </div>
              </div>
              <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-emerald-200">
                <div :style="{ width: progress + '%' }"
                  class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-emerald-500">
                </div>
              </div>
            </div>
            <!--END-->

            <div class="relative pt-1" style="margin-top: 50px;">
              <a href="#mi-div-vuelos"
                class="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-emerald-500 active:bg-emerald-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150">
                Ver vuelos
              </a>
              <a href="#mi-div-feed"
                class="github-star ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150">
                Ir al feed
              </a>
            </div>
          </div>
        </div>
      </div>

      <img
        class="absolute top-0 md:top-auto b-auto right-0 pt-16 sm:w-6/12 md:pt-48 -mt-16 sm:mt-0 w-10/12 md:w-5/12 max-h-860-px z-(-99)"
        :src="fondo1" alt="..." />

    </section>

    <section class="mt-48 md:mt-30 pb-40 relative bg-blueGray-100">
      <div class="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20" style="transform: translateZ(0);">
        <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
          version="1.1" viewBox="0 0 2560 100" x="0" y="0">
          <polygon class="text-blueGray-100 fill-current" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
      <div class="container mx-auto">
        <div class="flex flex-wrap items-center">
          <div class="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-32">
            <div
              class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-emerald-500">
              <img alt="..." :src="bali_img" class="w-full align-middle rounded-t-lg" />
              <blockquote class="relative p-8 mb-4">
                <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95"
                  class="absolute left-0 w-full block h-95-px -top-94-px">
                  <polygon points="-30,95 583,95 583,65" class="text-emerald-500 fill-current"></polygon>
                </svg>
                <h4 class="text-xl font-bold text-white">
                  Bali
                </h4>
                <p class="text-md font-light mt-2 text-white">
                  Bali tiene una población de más de 4 millones de personas, siendo su ciudad más poblada Denpasar, con
                  más de 830.000 habitantes.<br><br>
                  En Bali todos se llaman asi. Wayan para el primero, Made para el segundo, Nyoman para el tercero y
                  Ketut para el cuarto. <br><br>
                  Las coordenadas son:<br> 5.8874° N 10.0118° E
                </p>
              </blockquote>
            </div>
          </div>

          <div class="w-full md:w-6/12 px-4">
            <div class="flex flex-wrap">
              <div class="w-full md:w-6/12 px-4">
                <div class="relative flex flex-col mt-4">
                  <div class="px-4 py-5 flex-auto">
                    <div
                      class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                      <i class="fas fa-landmark"></i>
                    </div>
                    <h6 class="text-xl mb-1 font-semibold">
                      Embajada España
                    </h6>
                    <p class="mb-4 text-blueGray-500">
                      Teléfono: +62-21 314 23 55
                      Emergencia consular: +6281380013988 (081380013988 desde una línea indonesia)
                      Emergencia consular: +6281113205568 (08113205568 desde una línea indonesia)
                    </p>
                  </div>
                </div>
                <div class="relative flex flex-col min-w-0">
                  <div class="px-4 py-5 flex-auto">
                    <div
                      class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                      <i class="fas fa-motorcycle"></i>
                    </div>
                    <h6 class="text-xl mb-1 font-semibold">
                      Motos
                    </h6>
                    <p class="mb-4 text-blueGray-500">
                      Las caóticas circunstancias del tráfico en la mayor parte de Indonesia hacen frecuentes los
                      accidentes de tráfico. Son comunes los accidentes de motocicletas en las islas de Bali y Lombok.
                      <br> No te mates mi lokita. Xfa
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full md:w-6/12 px-4">
                <div class="relative flex flex-col min-w-0 mt-4">
                  <div class="px-4 py-5 flex-auto">
                    <div
                      class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                      <i class="fas fa-euro-sign"></i>
                    </div>
                    <h6 class="text-xl mb-1 font-semibold">Moneda</h6>
                    <p class="mb-4 text-blueGray-500">
                      La rupia es la moneda del país. <br> Los billetes en curso son de 100.000, 50.000, 20.000, 10.000,
                      5.000, 2.000 y 1.000 rupias. <br><br>Usa la revolut mejor, que te regalan tinder Gold
                    </p>
                  </div>
                </div>
                <div class="relative flex flex-col min-w-0">
                  <div class="px-4 py-5 flex-auto">
                    <div
                      class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                      <i class="fas fa-phone-alt"></i>
                    </div>
                    <h6 class="text-xl mb-1 font-semibold">
                      Telefonos locales
                    </h6>
                    <p class="mb-4 text-blueGray-500">
                      Polisiaaaa: 110<br>
                      Bomberos: 113<br>
                      Ambulancia 118<br>
                      Bali Taxi: 701111<br>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <header-stats />
      <div class="container mx-auto overflow-hidden pb-20">
        <div class="flex flex-wrap items-center">
          <div class="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-20">
            <div
              class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
              <i class="fas fa-euro-sign text-xl"></i>
            </div>
            <h3 class="text-3xl mb-2 font-semibold leading-normal">
              Dinerito
            </h3>
            <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
              Una minera siempre tiene las cuentas claras.
            </p>
            <div class="block pb-6">
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                EUR
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                IDR
              </span>

            </div>
          </div>

          <div class="w-full md:w-5/12 px-4 mr-auto ml-auto mt-10">
            <div class="relative flex flex-col min-w-0 w-full mb-6 mt-20 md:mt-0">
              <h2 class="text-2xl mb-4 text-center">Conversor de Moneda</h2>
              <div class="relative flex w-full flex-wrap items-stretch mb-3">
                <span
                  class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                  <i class="fas fa-euro-sign"></i>
                </span>
                <input type="number" v-model="eur" placeholder="EUR" @input="convertToIdr"
                  class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full pl-10" />
              </div>
              <div class="relative flex w-full flex-wrap items-stretch mb-3">
                <span
                  class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                  <i class="fas fa-rupee-sign"></i>
                </span>
                <input type="number" v-model="idr" placeholder="IDR" @input="convertToEur"
                  class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full pl-10" />
              </div>
            </div>
          </div>
        </div>


      </div>


      <!-- Header -->
      <div class="relative bg-lightBlue-400 md:pt-32 pb-32 pt-12" style="padding-top: 50px;">
        <h3 class="text-4xl font-normal leading-normal mt-0 mb-2 text-white text-center" style="padding-bottom: 30px;">
          En directo
        </h3>
        <div class="px-4 md:px-10 mx-auto w-full">
          <div>
            <!-- Card stats -->
            <div class="flex flex-wrap">
              <div class="w-full md:w-6/12 xl:w-4/12 px-4">

                <h5 class="text-1xl font-normal leading-normal mt-0 mb-2 text-white text-center"
                  style="padding-bottom: 30px;">
                  CALLAO MADRID
                </h5>
                <img src="https://informo.madrid.es/cameras/Camara01314.jpg?rand=1714217340085">
              </div>
              <div class="w-full md:w-6/12 xl:w-4/12 px-4">
                <h5 class="text-1xl font-normal leading-normal mt-0 mb-2 text-white text-center"
                  style="padding-bottom: 30px;">
                  PLAYA BALI
                </h5>
                <iframe width="100%" height="315"
                  src="https://www.youtube.com/embed/9lvg4TwN5Ao?si=fCrG6AOMFh_C0oam&amp;controls=0"
                  title="Webcam en directo" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div id="mi-div-vuelos" class="justify-center text-center flex flex-wrap mt-24">
        <div class="w-full md:w-6/12 px-12 md:px-4">
          <h2 class="font-semibold text-4xl">Avioncitos</h2>
          <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
            Volando desde que te conozco, y ahora viendo como vuelas tu.
          </p>
          <p>La informacion de los vuelos estara disponible una vez el avioooooon encienda el transponder y notifique su
            call-sign</p>
        </div>
      </div>
    </section>


    <section class="block relative z-1 bg-blueGray-600">
      <div class="container mx-auto">
        <div class="justify-center flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4 -mt-24">
            <div class="flex flex-wrap">
              <div class="w-full lg:w-4/12 px-4">
                <h5 class="text-xl font-semibold pb-4 text-center"> MAD -> HK </h5>
                <div
                  class="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                  <iframe class="align-middle border-none max-w-full h-auto rounded-lg"
                    src="https://www.flightradar24.com/simple?reg=B-LRV" frameborder="0" width="100%" height="300"
                    allowfullscreen></iframe>
                </div>


              </div>
              <div class="w-full lg:w-4/12 px-4">
                <h5 class="text-xl font-semibold pb-4 text-center">HK -> DPS </h5>
                <div
                  class="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                  <iframe class="align-middle border-none max-w-full h-auto rounded-lg"
                    src="https://www.flightradar24.com/simple?reg=B-LRG" frameborder="0" width="100%" height="300"
                    allowfullscreen></iframe>
                </div>

              </div>
              <div class="w-full lg:w-4/12 px-4">
                <h5 class="text-xl font-semibold pb-4 text-center"> DPS -> HK </h5>
                <div
                  class="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                  <iframe class="align-middle border-none max-w-full h-auto rounded-lg"
                    src="https://www.flightradar24.com/simple?reg=B-LRG" frameborder="0" width="100%" height="300"
                    allowfullscreen></iframe>
                </div>

              </div>
              <div class="w-full lg:w-4/12 px-4">
                <h5 class="text-xl font-semibold pb-4 text-center"> HK -> MAD </h5>
                <div
                  class="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                  <iframe class="align-middle border-none max-w-full h-auto rounded-lg"
                    src="https://www.flightradar24.com/simple?reg=B-LRG" frameborder="0" width="100%" height="300"
                    allowfullscreen></iframe>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="py-20 bg-blueGray-600 overflow-hidden">
      <div id="mi-div-feed" class="container mx-auto px-4 pb-32 pt-48" style="background-color: beige;">
        <div class="w-full text-center lg:w-8/12">
          <p class="text-4xl text-center">
            <span role="img" aria-label="love">
              📰
            </span>
          </p>
          <h3 class="font-semibold text-3xl">
            Feed
          </h3>
          <p class="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
            Ultimas actualizaciones.
          </p>


          <div class="text-center mt-16"></div>
        </div>
        <div class="items-center flex flex-wrap">
          <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div class="md:pr-12">


              <h6 class="text-xl font-normal leading-normal mt-0 mb-2 text-emerald-800">Primer update: Rarita se pasa de
                equipaje</h6>
              <p class="mt-4 text-lg leading-relaxed text-blueGray-500"> Mi rarita ha llenado 24kg entre bañadores y
                repelente de mosquitos</p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div><span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"><i
                          class="fas fa-calendar"></i></span></div>
                    <div>
                      <h4 class="text-blueGray-500"> 26/04/24 </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          </div>
          <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0" style="padding-top: 20px;"><img alt="..."
              class="max-w-full rounded-lg shadow-xl" :src="feed1"
              style="transform: scale(1) perspective(1040px) rotateY(-8deg); margin-bottom: 40px;"></div>
        </div>

        <!--update2-->
        <div class="items-center flex flex-wrap">
          <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div class="md:pr-12">


              <h6 class="text-xl font-normal leading-normal mt-0 mb-2 text-emerald-800">Segundo update: A volar</h6>
              <p class="mt-4 text-lg leading-relaxed text-blueGray-500"> CX0372 con Destino HK! Pista 14R. Proximo
                update ya en la otra parte del mundo</p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div><span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"><i
                          class="fas fa-calendar"></i></span></div>
                    <div>
                      <h4 class="text-blueGray-500"> 26/04/24 </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          </div>
          <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0" style="padding-top: 20px;"><img alt="..."
              class="max-w-full rounded-lg shadow-xl" :src="feed2"
              style="transform: scale(1) perspective(1040px) rotateY(-8deg); margin-bottom: 40px;"></div>
        </div>
        <!--fin2-->

        <!--update3-->
        <div class="items-center flex flex-wrap">
          <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div class="md:pr-12">


              <h6 class="text-xl font-normal leading-normal mt-0 mb-2 text-emerald-800">Tercer update: Hong Kong!</h6>
              <p class="mt-4 text-lg leading-relaxed text-blueGray-500"> 01:20 de la mañana en Madrid, 07:20 en HK.
                LLegando a tu primer destino. TQ ❤️</p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div><span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"><i
                          class="fas fa-calendar"></i></span></div>
                    <div>
                      <h4 class="text-blueGray-500"> 27/04/24 </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          </div>
          <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0" style="padding-top: 20px;"><img alt="..."
              class="max-w-full rounded-lg shadow-xl" :src="feed3"
              style="transform: scale(1) perspective(1040px) rotateY(-8deg); margin-bottom: 40px;"></div>
        </div>
        <!--fin3-->

        <!--update4-->
        <div class="items-center flex flex-wrap">
          <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div class="md:pr-12">


              <h6 class="text-xl font-normal leading-normal mt-0 mb-2 text-emerald-800">Cuarto update: Enganiados!</h6>
              <p class="mt-4 text-lg leading-relaxed text-blueGray-500"> Bueno, aqui habia pensado en poner toda la
                conversacion con los amigos de cathay y despues ilustrarlo con una foto de la flamante tarta y tu
                mensaje de se te fue la cabesa bro... Pero... ya te sabes la historia, asi que la resumo con esta
                imagen.</p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div><span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"><i
                          class="fas fa-calendar"></i></span></div>
                    <div>
                      <h4 class="text-blueGray-500"> 27/04/24 </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          </div>
          <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0" style="padding-top: 20px;"><img alt="..."
              class="max-w-full rounded-lg shadow-xl" :src="feed4"
              style="transform: scale(1) perspective(1040px) rotateY(+2deg); margin-bottom: 40px;"></div>
        </div>

        <!--update5-->
        <div class="items-center flex flex-wrap">
          <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div class="md:pr-12">


              <h6 class="text-xl font-normal leading-normal mt-0 mb-2 text-emerald-800">Quinto update: Desde el paraiso
                y completamente in love!</h6>
              <p class="mt-4 text-lg leading-relaxed text-blueGray-500"> Supongo que no tienes ninguna duda, pero es
                increible como consigues que desde la otra punta del mundo sienta como si estuvieras a 20 minutos, me
                encantaria estar alli contigo viendo todo esto, pero estoy disfrutando infinito viendote y me siento
                como si estuviera alli contigo. Gracias mi niña, te quiero</p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div><span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"><i
                          class="fas fa-calendar"></i></span></div>
                    <div>
                      <h4 class="text-blueGray-500"> 28/04/24 </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          </div>
          <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0" style="padding-top: 20px;"><img alt="..."
              class="max-w-full rounded-lg shadow-xl" :src="feed5"
              style="transform: scale(1) perspective(1040px) rotateY(+2deg); margin-bottom: 40px;"></div>
        </div>
        <!--fin5-->

        <!--update6-->
        <div class="items-center flex flex-wrap">
          <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div class="md:pr-12">


              <h6 class="text-xl font-normal leading-normal mt-0 mb-2 text-emerald-800">Sexto update: Clases de
                indonesio for yu!</h6>
              <p class="mt-4 text-lg leading-relaxed text-blueGray-500"> Bueno, pues no se por que pero hoy me ha dado
                por pensar que nos teniamos que echar unas risas, asi que aqui en video te dejo algo que no te
                esperas... Y que puede que haga que no quieras volver... Jajajaj. Buenos Dias!! Te Quiero.</p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div><span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"><i
                          class="fas fa-calendar"></i></span></div>
                    <div>
                      <h4 class="text-blueGray-500"> 29/04/24 </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          </div>
          <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0" style="padding-top: 20px;"><video ref="videoPlayer"
              class="video-js vjs-fluid" controls preload="auto"></video></div>
        </div>
        <!--fin6-->

                <!--update7-->
                <div class="items-center flex flex-wrap">
          <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div class="md:pr-12">


              <h6 class="text-xl font-normal leading-normal mt-0 mb-2 text-emerald-800">Septimo update: Hoy toca ponerse mistico</h6>
              <p class="mt-4 text-lg leading-relaxed text-blueGray-500"> Despues del mongolo de arriba intentando hablar balines, hoy, me quedo con esta fotito. Ganesha, que ente muchos significados (para eso tendra tantos brazos supongo) es el dios de los nuevos comienzos, se dice que su presencia trae fortuna en todo lo nuevo que se empieza. <br> Misticismo o no, cuando te abrace vas a sentir que tengo mas brazos que ganesha, por que no vas a poder escapar!!!! Ni llamando a la polisiiaa ni pidiendo aiuto.</p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div><span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"><i
                          class="fas fa-calendar"></i></span></div>
                    <div>
                      <h4 class="text-blueGray-500"> 30/04/24 </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          </div>
          <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0" style="padding-top: 20px;"><img alt="..."
              class="max-w-full rounded-lg shadow-xl" :src="feed7"
              style="transform: scale(1) perspective(1040px) rotateY(+8deg); margin-bottom: 40px;"></div>
        </div>
        <!--fin7-->

                        <!--update8-->
                        <div class="items-center flex flex-wrap">
          <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div class="md:pr-12">


              <h6 class="text-xl font-normal leading-normal mt-0 mb-2 text-emerald-800">Octavo update: Cansino 4 YOU</h6>
              <p class="mt-4 text-lg leading-relaxed text-blueGray-500"> Espero que tanta intensidad no te sature, por que quedan {{ days }} días, {{ hours }} horas,
              {{ minutes }} minutos y
              {{ seconds }} segundos para volverte a espachurrar y pienso subir una de mis tonterias a diario. <br> Por si no te queda claro le he pedido a la IA que haga una representacion fiel a la realidad. <br><br> <i>Encuentra a tu minero pico y pala favorito en la imagen.</i></p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div><span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"><i
                          class="fas fa-calendar"></i></span></div>
                    <div>
                      <h4 class="text-blueGray-500"> 1/05/24 </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          </div>
          <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0" style="padding-top: 20px;"><img alt="..."
              class="max-w-full rounded-lg shadow-xl" :src="feed8"
              style="transform: scale(1) perspective(1040px) rotateY(-6deg); margin-bottom: 40px;"></div>
        </div>
        <!--fin8-->

                        <!--update9 Musica-->
                        <div class="items-center flex flex-wrap">
          <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div class="md:pr-12">


              <h6 class="text-xl font-normal leading-normal mt-0 mb-2 text-emerald-800">Noveno update: Musiquita</h6>
              <p class="mt-4 text-lg leading-relaxed text-blueGray-500"> Emergenssiiia!!!.🚨 Que se note que somos dos viejunos, un poquito de regueton del weno,... Como puede ser que esta web que tiene de todo todavia no tuviese un poquito de musiquita!. <br><br>🎶 Ven aqui rapido, Ven aqui rapido, llamado de emergensia🎶 <br><br> TE QUIERO</p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div><span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"><i
                          class="fas fa-calendar"></i></span></div>
                    <div>
                      <h4 class="text-blueGray-500"> 2/05/24 </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          </div>
          <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0" style="padding-top: 20px;">
            <iframe style="border-radius:12px" src="https://open.spotify.com/embed/track/2jnZUvhw2LTvDI6YidRVcO?utm_source=generator&theme=0" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>

          </div>
        </div>
        <!--fin9-->

                               <!--update10-->
                               <div class="items-center flex flex-wrap">
          <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div class="md:pr-12">


              <h6 class="text-xl font-normal leading-normal mt-0 mb-2 text-emerald-800">Decimo update: Primera Semana</h6>
              <p class="mt-4 text-lg leading-relaxed text-blueGray-500"> No puedo decir que no se me haya hecho larga esta semana por que seria mentir. Me habria encantado poder ver y compartir todos esos sitios contigo, poder gritar ensima de una furgo juntos, o poder aprender de vida marina. <br>Dicho eso, estoy contento de que a pesar de la distancia nuestras ganas y nuestro amor estan haciendo las cosas un poquito mas faciles. Creo que esos cimientos de hormigon de los que alguna vez hemos hablado son cada dia mas fuertes. <br><br> En linea con ser un buen psicopata me ha parecido interesante  analizar nuestra primera semana en datos (de Whatsapp) y, aunque son muy curiosos hay algo que los datos no pueden sacar que es todo lo que te echo de menos!!!. <br><br> Sigue disfrutando tu aventura. TQM</p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div><span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"><i
                          class="fas fa-calendar"></i></span></div>
                    <div>
                      <h4 class="text-blueGray-500"> 3/05/24 </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          </div>
          <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0" style="padding-top: 20px;"><img alt="..."
              class="max-w-full rounded-lg shadow-xl" :src="feed10"
              style="transform: scale(1) perspective(1040px) rotateY(-1deg); margin-bottom: 40px;"></div>
        </div>
        <!--fin10-->

                                       <!--update11-->
                                       <div class="items-center flex flex-wrap">
          <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div class="md:pr-12">


              <h6 class="text-xl font-normal leading-normal mt-0 mb-2 text-emerald-800">Undecimo update: Mongers at Phone</h6>
              <p class="mt-4 text-lg leading-relaxed text-blueGray-500"> Hoy querias ayudarme con el update y me diste la idea de tomar un pantallazo. Como no, dos monguers haciendo el monguer,... pero de pronto, algo sucedio en las ruidosas, ajetreadas y ladyboizadas calles de Ubud. <br>Mi querida monguer se habia perdido!!! Y asi nace...  <br><br>Lost in Ubud, THE COMIC</p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div><span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"><i
                          class="fas fa-calendar"></i></span></div>
                    <div>
                      <h4 class="text-blueGray-500"> 4/05/24 </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          </div>
          <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0" style="padding-top: 20px;"><img alt="..."
              class="max-w-full rounded-lg shadow-xl" :src="feed11"
              style="transform: scale(1) perspective(1040px) rotateY(-8deg); margin-bottom: 40px;"></div>
        
          <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0" style="padding-top: 20px;"><img alt="..."
              class="max-w-full rounded-lg shadow-xl" :src="feed11a"
              style="transform: scale(1) perspective(1040px) rotateY(-1deg); margin-bottom: 40px;"></div>
        </div>
        <!--fin11-->
        <!--update12-->
        <div class="items-center flex flex-wrap">
          <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div class="md:pr-12">


              <h6 class="text-xl font-normal leading-normal mt-0 mb-2 text-emerald-800">Duodecimo update: Orient chef a su servicio!</h6>
              <p class="mt-4 text-lg leading-relaxed text-blueGray-500"> Pensabas que te ibas a librar de que te diera el coñazo con todo lo que habia comprado en el super?? De eso nada!!. <br><br>Tu cutre chef te quiere!!</p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div><span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"><i
                          class="fas fa-calendar"></i></span></div>
                    <div>
                      <h4 class="text-blueGray-500"> 05/05/24 </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          </div>
          <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0" style="padding-top: 20px;"><video ref="videoPlayer2"
              class="video-js vjs-fluid" controls preload="auto"></video></div>
        </div>
        <!--fin13-->
                                       <!--update13-->
                                       <div class="items-center flex flex-wrap">
          <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div class="md:pr-12">


              <h6 class="text-xl font-normal leading-normal mt-0 mb-2 text-emerald-800">Decimotercer update: ¿Y esos calcetines tan chulos?</h6>
              <p class="mt-4 text-lg leading-relaxed text-blueGray-500"> Que me encantan los calcetines no es ninguna novedad, pero a la gente tambien le gusta ver mis calcetines molones. <br>- ¿Y esos calcetines?. <br>- Molan eh!<br>- Siempre llevas calcetines con muñecos<br>- Son de camaras, me los ha regalado Lara <br>- Ahhhh que bonitos!! <br><br>Me encantan mis calcetines de fotitos y lugares 😊. TQ mi niña</p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div><span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"><i
                          class="fas fa-calendar"></i></span></div>
                    <div>
                      <h4 class="text-blueGray-500"> 6/05/24 </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          </div>
          <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0" style="padding-top: 20px;"><img alt="..."
              class="max-w-full rounded-lg shadow-xl" :src="feed13"
              style="transform: scale(1) perspective(1040px) rotateY(-1deg); margin-bottom: 40px;"></div>
        </div>
        <!--fin13-->
                                              <!--update14-->
                                              <div class="items-center flex flex-wrap">
          <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div class="md:pr-12">


              <h6 class="text-xl font-normal leading-normal mt-0 mb-2 text-emerald-800">Decimocuarto update: Sin piños, pero con Update!!</h6>
              <p class="mt-4 text-lg leading-relaxed text-blueGray-500"> Se me esta cayendo la baba (para variar), pero he ido con la camiseta de buceadora PRO de mi ñiña. <br>Busseadooor sin dientesitos jajaja<br>No tengo la mejor cara, pero contento por llevar tu camiseta y sentirte mas cerquita ❤️.</p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div><span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"><i
                          class="fas fa-calendar"></i></span></div>
                    <div>
                      <h4 class="text-blueGray-500"> 7/05/24 </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          </div>
          <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0" style="padding-top: 20px;"><img alt="..."
              class="max-w-full rounded-lg shadow-xl" :src="feed14"
              style="transform: scale(1) perspective(1040px) rotateY(+9deg); margin-bottom: 40px;"></div>
        </div>
        <!--fin14-->
                                                      <!--update15-->
                                                      <div class="items-center flex flex-wrap">
          <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div class="md:pr-12">


              <h6 class="text-xl font-normal leading-normal mt-0 mb-2 text-emerald-800">Decimoquinto update: Mi terremoto eres tu</h6>
              <p class="mt-4 text-lg leading-relaxed text-blueGray-500">  La verdad es que ni por lo mas remoto me habia imaginado tener que escribir un update hablando de esto!!<br>Asi son las aventuras, que siempre le tienen su emocion. <br><br>No negare que en cuanto me lo has dicho he ido corriendo a buscar informacion que me he asustado 🥺🥺 <br><br> Me encantan las aventuras, pero xfa, vuelve de una pieza!. Te dejo consejitos sismicos. TE I LOVE U</p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div><span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"><i
                          class="fas fa-calendar"></i></span></div>
                    <div>
                      <h4 class="text-blueGray-500"> 8/05/24 </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          </div>
          <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0" style="padding-top: 20px;"><img alt="..."
              class="max-w-full rounded-lg shadow-xl" :src="feed15"
              style="transform: scale(1) perspective(1040px) rotateY(-7deg); margin-bottom: 40px;"></div>
        </div>
        <!--fin15-->
        <!--update16-->
        <div class="items-center flex flex-wrap">
          <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div class="md:pr-12">


              <h6 class="text-xl font-normal leading-normal mt-0 mb-2 text-emerald-800">Decimosexto update: Mas terremotos</h6>
              <p class="mt-4 text-lg leading-relaxed text-blueGray-500">  No podia ser que entre todas las actualizaciones de nuestra web no estuvieran ellos <br><br>Acuerdate que tienes en España esperandote a dos monguers caninos y un monguer persona. Los tres muy contentos y con muchas ganas de llenarte de babas <br><br> 🏡🐶🐶👫💖</p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div><span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"><i
                          class="fas fa-calendar"></i></span></div>
                    <div>
                      <h4 class="text-blueGray-500"> 9/05/24 </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          </div>
          <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0" style="padding-top: 20px;"><img alt="..."
              class="max-w-full rounded-lg shadow-xl" :src="feed16"
              style="transform: scale(1) perspective(1040px) rotateY(+7deg); margin-bottom: 40px;"></div>
        </div>
        <!--fin16-->
                <!--update17-->
                <div class="items-center flex flex-wrap">
          <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div class="md:pr-12">


              <h6 class="text-xl font-normal leading-normal mt-0 mb-2 text-emerald-800">Decimoseptimo update: Segunda semana</h6>
              <p class="mt-4 text-lg leading-relaxed text-blueGray-500">  Aqui llegan los datitos 100tifikos de esta segunda semana. Ya la ultima completa <br><br>Lo seguimos haciendo increible y me encanta todo. Nada mas que añadir.  <br><br> TE QUIERO</p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div><span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"><i
                          class="fas fa-calendar"></i></span></div>
                    <div>
                      <h4 class="text-blueGray-500"> 10/05/24 </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          </div>
          <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0" style="padding-top: 20px;"><img alt="..."
              class="max-w-full rounded-lg shadow-xl" :src="feed17"
              style="transform: scale(1) perspective(1040px) rotateY(-2deg); margin-bottom: 40px;"></div>
        </div>
        <!--fin17-->
                        <!--update18-->
                        <div class="items-center flex flex-wrap">
          <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div class="md:pr-12">


              <h6 class="text-xl font-normal leading-normal mt-0 mb-2 text-emerald-800">Decimoctavo update: BadCrab</h6>
              <p class="mt-4 text-lg leading-relaxed text-blueGray-500">  Los siberdelincuentes querian dejarnos sin web!! De eso nada!!! <br><br>El update de hoy una tonteria, pero en realidad me ha costado un huevo jajaja... Estoy perdiendo la cabesa (mas) necesito que vuelvas.   <br><br> Puedes tener claro que esta web no nos la rompen. Que me hara mucha ilusion ver el codigo contigo dentro de mucho tiempo ❤️ TE QUIEROOOOOOO🦀 </p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div><span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"><i
                          class="fas fa-calendar"></i></span></div>
                    <div>
                      <h4 class="text-blueGray-500"> 11/05/24 </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          </div>
          <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0" style="padding-top: 20px;"><img alt="..."
              class="max-w-full rounded-lg shadow-xl" :src="feed18"
              style="transform: scale(1) perspective(1040px) rotateY(-7deg); margin-bottom: 40px;"></div>
        </div>
        <!--fin18-->
                <!--update19-->
                <div class="items-center flex flex-wrap">
          <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div class="md:pr-12">


              <h6 class="text-xl font-normal leading-normal mt-0 mb-2 text-emerald-800">Decimonoveno update: Mio goreng!</h6>
              <p class="mt-4 text-lg leading-relaxed text-blueGray-500"> Una manera de viajar es mediante la comida. Me encanta intentar replicar los platitos ricos y practicar para cuando vuelvas comerlo juntos <br><br>Disfrute del programa de cocina de Carlos No-Arguiñano</p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div><span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"><i
                          class="fas fa-calendar"></i></span></div>
                    <div>
                      <h4 class="text-blueGray-500"> 12/05/24 </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          </div>
          <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0" style="padding-top: 20px;"><video ref="videoPlayer3"
              class="video-js vjs-fluid" controls preload="auto"></video></div>
        </div>
        <!--fin19-->
                        <!--update20-->
                        <div class="items-center flex flex-wrap">
          <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div class="md:pr-12">


              <h6 class="text-xl font-normal leading-normal mt-0 mb-2 text-emerald-800">Vigesimo update: A mano</h6>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div><span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"><i
                          class="fas fa-calendar"></i></span></div>
                    <div>
                      <h4 class="text-blueGray-500"> 13/05/24 </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          </div>
          <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0" style="padding-top: 20px;"><img alt="..."
              class="max-w-full rounded-lg shadow-xl" :src="feed20"
              style="transform: scale(1) perspective(1040px) rotateY(-1deg); margin-bottom: 40px;"></div>
        </div>
        <!--fin20-->
                                <!--update21-->
                                <div class="items-center flex flex-wrap">
          <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div class="md:pr-12">


              <h6 class="text-xl font-normal leading-normal mt-0 mb-2 text-emerald-800">Vigesimoprimer update: HongKong</h6>
              <p class="mt-4 text-lg leading-relaxed text-blueGray-500">  Que cerquita estas ya 😀😀😀  <br><br>Hoy el update cambia de hora para adaptarse <br><br> Ya en HongKong, te estoy vigilando para que no te vayas a otro lado que quiero abrasito ya 🧢🧢 ❤️ TE QUIEROO VIAJERA </p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div><span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"><i
                          class="fas fa-calendar"></i></span></div>
                    <div>
                      <h4 class="text-blueGray-500"> 14/05/24 </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          </div>
          <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0" style="padding-top: 20px;"><img alt="..."
              class="max-w-full rounded-lg shadow-xl" :src="feed21"
              style="transform: scale(1) perspective(1040px) rotateY(-2deg); margin-bottom: 40px;"></div>
        </div>
        <!--fin21-->
        
      </div>

    </section>


    <section class="pb-16 bg-blueGray-200 relative pt-32">
      <div class="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20" style="transform: translateZ(0);">
        <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
          version="1.1" viewBox="0 0 2560 100" x="0" y="0">
          <polygon class="text-blueGray-200 fill-current" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>

      <div class="container mx-auto">
        <div class="flex flex-wrap justify-center bg-white shadow-xl rounded-lg -mt-64 py-16 px-12 relative z-10">
          <div class="w-full text-center lg:w-8/12">
            <p class="text-4xl text-center">
              <span role="img" aria-label="love">
                😍
              </span>
            </p>
            <h3 class="font-semibold text-3xl">
              Disfruta mucho la aventura
            </h3>
            <p class="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
              No te olvides que hay un minero y dos perretes esperandote en Madrid, pero mientras, bucea, descubre, toma
              el sol, descansa, come y sonrrie. <br> Te quiero.
            </p>
            <!--
            <div class="sm:block flex flex-col mt-10">
              <a href="https://www.creative-tim.com/learning-lab/tailwind/vue/overview/notus?ref=vn-index"
                target="_blank"
                class="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-emerald-500 active:bg-emerald-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150">
                Get started
              </a>
              <a href="https://github.com/creativetimofficial/vue-notus?ref=vn-index" target="_blank"
                class="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150">
                <i class="fab fa-github text-lg mr-1"></i>
                <span>Help With a Star</span>
              </a>
            </div>

            -->

            <div class="text-center mt-16"></div>
          </div>
        </div>
      </div>
    </section>
    <footer-component />

  </div>
</template>
<script>


import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";

import HeaderStats from "@/components/Headers/HeaderStats.vue";


import FooterComponent from "@/components/Footers/Footer.vue";

//feed
import feed1 from "@/assets/img/updates/1.jpeg";
import feed2 from "@/assets/img/updates/2.gif";
import feed3 from "@/assets/img/updates/3.png";
import feed4 from "@/assets/img/updates/4.png";
import feed5 from "@/assets/img/updates/5.gif";
import feed7 from "@/assets/img/updates/7.jpeg";
import feed8 from "@/assets/img/updates/8.png";
import videojs from "video.js";
import feed10 from "@/assets/img/updates/10.png";
import feed11 from "@/assets/img/updates/11.jpg";
import feed11a from "@/assets/img/updates/11a.png";
import feed13 from "@/assets/img/updates/13.png";
import feed14 from "@/assets/img/updates/14.jpg";
import feed15 from "@/assets/img/updates/15.png";
import feed16 from "@/assets/img/updates/16.gif";
import feed17 from "@/assets/img/updates/17.png";
import feed18 from "@/assets/img/updates/18.jpg";
import feed20 from "@/assets/img/updates/20.png";
import feed21 from "@/assets/img/updates/21.png";
import "video.js/dist/video-js.css";
//endfeed

import bali_img from "@/assets/img/bali.jpg";
import patternVue from "@/assets/img/pattern_vue.png";
import fondo2 from "@/assets/img/fondo2.jpg";
import fondo1 from "@/assets/img/fondo1.png";
import componentBtn from "@/assets/img/component-btn.png";
import componentProfileCard from "@/assets/img/component-profile-card.png";
import componentInfoCard from "@/assets/img/component-info-card.png";
import componentInfo2 from "@/assets/img/component-info-2.png";
import componentMenu from "@/assets/img/component-menu.png";
import componentBtnPink from "@/assets/img/component-btn-pink.png";
import documentation from "@/assets/img/documentation.png";
import login from "@/assets/img/login.jpg";
import aviones from "@/assets/img/aviones.jpg";
import profile from "@/assets/img/profile.jpg";
import landing from "@/assets/img/landing.jpg";

import moment from 'moment';
import axios from 'axios';

export default {
  name: "VideoPlayer",
  data() {
    return {
      feed1,
      feed2,
      feed3,
      feed4,
      feed5,
      feed7,
      feed8,
      feed10,
      feed11,
      feed11a,
      feed13,
      feed14,
      feed15,
      feed16,
      feed17,
      feed18,
      feed20,
      feed21,
      bali_img,
      HeaderStats,
      patternVue,
      fondo1,
      fondo2,
      componentBtn,
      componentProfileCard,
      componentInfoCard,
      componentInfo2,
      componentMenu,
      componentBtnPink,
      documentation,
      login,
      aviones,
      profile,
      landing,
      //Exports para el contador
      targetDate: null,
      startDate: null,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      progress: 0,
      eur: null,
      idr: null,
      rate: null
    };
  },
  computed: {
    progressText() {
      if (this.progress <= 0) {
        return 'Viaje todavía no iniciado';
      } else if (this.progress < 100) {
        return 'Viaje en curso';
      } else {
        return 'Viaje completado';
      }
    },
  },
  created() {
    this.targetDate = moment('2024-05-15T08:50:00+02:00');
    this.startDate = moment('2024-04-26T08:00:00+02:00');
    this.updateCountdown();
    setInterval(this.updateCountdown, 1000);
  },
  methods: {
    updateCountdown() {
      const now = moment();
      const totalDuration = this.targetDate.diff(this.startDate);
      const remainingDuration = this.targetDate.diff(now);

      this.days = Math.floor(moment.duration(remainingDuration).asDays());
      this.hours = moment.duration(remainingDuration).hours();
      this.minutes = moment.duration(remainingDuration).minutes();
      this.seconds = moment.duration(remainingDuration).seconds();

      this.progress = 100 - (remainingDuration / totalDuration) * 100;
    },
    async convert() {
      const response = await axios.get('https://api.currencyapi.com/v3/latest?apikey=cur_live_y0yW2Kxw0GbhyBo7Q0E7OyNuzwYKW8xI9Q6w7NLa');
      this.rates = response.data.data;
      this.convertToIdr();
      this.convertToEur();
    },
    convertToIdr() {
      this.idr = (this.eur * this.rates.IDR.value).toFixed(6);
    },
    convertToEur() {
      this.eur = (this.idr / this.rates.IDR.value).toFixed(2);
    },

    goToVuelos() {
      // Encuentra el elemento con el id específico
      const targetElement = document.getElementById('mi-div-vuelos');

      // Desplázate suavemente al elemento
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start', // Opcional: 'start', 'center', o 'end'
        });
      }
    },
    goToFeed() {
      // Implement logic to scroll to the feed section
      const targetElement = document.getElementById('mi-div-feed');
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    },

  },

  mounted() {
    this.convert();
    // Inicializa Video.js en el elemento de video
    this.player = videojs(this.$refs.videoPlayer, {
      sources: [
        {
          src: "https://bali.minerolabs.com/indonesio.mp4", // Reemplaza con la URL real de tu video
          type: "video/mp4",
        },
      ],
    });
    // Inicializa Video.js en el segundo elemento de video
    this.player2 = videojs(this.$refs.videoPlayer2, {
      sources: [
        {
          src: "https://bali.minerolabs.com/chef.mp4", // Añade la ruta correcta a tu archivo chef.mp4
          type: "video/mp4",
        },
      ],
    });
    this.player3 = videojs(this.$refs.videoPlayer3, {
      sources: [
        {
          src: "https://bali.minerolabs.com/miegoreng.mp4", // Añade la ruta correcta a tu archivo chef.mp4
          type: "video/mp4",
        },
      ],
    });
  },
  beforeDestroy() {
    // Destruye la instancia de Video.js antes de que se elimine el componente
    if (this.player) {
      this.player.dispose();
    }
  },
  components: {
    IndexNavbar,
    HeaderStats,
    FooterComponent,
  },
};
</script>
